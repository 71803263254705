/**
 * Created by gantushig on 11/6/15.
 */

import React from "react";
import ProductGTINViewFragment from "./ProductGTINViewFragment";
import ProductExpCountriesFragment from "./ProductExpCountriesFragment";

const ProductGTINView = ({
                         p, image1, image2, image3
                     }) => {


    return (

            <table className="table-fixed shadow-lg bg-white border-collapse w-full">
                <tbody>
                <ProductGTINViewFragment p={p} isProduct={true}/>
                <ProductExpCountriesFragment p={p}/>

                <tr>
                    <th className="bg-blue-100 border text-left px-8 py-4">ҮНДСЭН АНГИЛАЛ</th>
                    <td className="border px-8 py-4">{p && p.main_name}</td>
                </tr>
                <tr>
                    <th className="bg-blue-100 border text-left px-8 py-4">ДЭД АНГИЛАЛ</th>
                    <td className="border px-8 py-4">{p && p.sub_name}</td>
                </tr>
                <tr>
                    <th className="bg-blue-100 border text-left px-8 py-4">НЭГЖ АНГИЛАЛ</th>
                    <td className="border px-8 py-4">{p && p.unit_name}</td>
                </tr>
                {/*<tr>*/}
                {/*    <th>Бүтээгдэхүүний Зураг</th>*/}
                {/*    <td>*/}
                {/*        <Link to={`/product/${p.id}/images`} className="button is-small">*/}
                {/*            Үзэх*/}
                {/*        </Link>*/}

                {/*    </td>*/}
                {/*</tr>*/}
                <tr>
                    <th className="bg-blue-100 border text-left px-8 py-4">Бүтээгдэхүүний Зураг 1</th>
                    <td className="border px-8 py-4">
                        <div>
                            <img src={image1} width={300}/>
                        </div>
                    </td>
                </tr>
                <tr>
                    <th className="bg-blue-100 border text-left px-8 py-4">Бүтээгдэхүүний Зураг 2</th>
                    <td className="border px-8 py-4">
                        <div>
                            <img src={image2} width={300}/>
                        </div>
                    </td>
                </tr>
                <tr>
                    <th className="bg-blue-100 border text-left px-8 py-4">Бүтээгдэхүүний Зураг 1</th>
                    <td className="border px-8 py-4">
                        <div>
                            <img src={image3} width={300}/>
                        </div>
                    </td>
                </tr>

                </tbody>
            </table>
    )

}


export default ProductGTINView

