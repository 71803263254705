/**
 * Created by gantushig on 11/6/15.
 */

import React from "react";
import InvoiceViewComp from "./components/InvoiceViewComp"
import {useLocation} from "react-router-dom";
import {Endpoints} from "./Constants";
import FileDownload from "js-file-download";
import axios2 from './axiosConfig2'
import "./scss/InvoicePayView.scss"

const InvoicePayView = () => {
    const location = useLocation();

    const invoices = location.state.invoices;
    if (invoices === null || invoices === undefined || invoices.length === 0) {
        return (<div>
                <p>Not found</p>
            </div>
        );
    }
    const handlePrint = (invoice) => {
        async function printInvoice() {
            let config = {};
            config["url"] = `${Endpoints.INVOICE_ADMIN}/${invoice.id}/print`;
            config["responseType"] = "arraybuffer";

            let response = await axios2(config);
            const fileNameHeader = "x-suggested-filename";
            const suggestedFileName = response.headers[fileNameHeader]
            let fn = decodeURIComponent(suggestedFileName);

            FileDownload(response.data, fn)
        }
        printInvoice();
    };


    const invoices_array = invoices.map(invoice => {
        return ( <div>
            <button className="rounded-md bg-orange-500 border text-white p-2" onClick={() => handlePrint(invoice)}>Print</button>
            <InvoiceViewComp invoice={invoice}/>
        </div>)
    }
    );

    return (
        <div className="p-2" style={{backgroundColor: "white"}}>
            {invoices_array}
            <br/><br/>

        </div>
    )

}


export default InvoicePayView;


