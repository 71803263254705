/**
 * Created by gantushig on 11/6/15.
 */

import React from "react";
import ProductGTINViewFragment from "./ProductGTINViewFragment";
import ProductSenderFragment from "./ProductSenderFragment";
import ProductApplExpCountriesFragment from "./ProductApplExpCountriesFragment";

const ProductApplGTINView = ({
                                 request
                             }) => {


    return (

        <table className="table-fixed w-full shadow-lg bg-white border-collapse">
            <tbody>

            <ProductGTINViewFragment p={request} isProduct={false}/>
            <ProductApplExpCountriesFragment p={request}/>

            <ProductSenderFragment p={request}/>

            </tbody>
        </table>
    )

}


export default ProductApplGTINView

